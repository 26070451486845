




















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import { CardStep as ICardStep } from '@/contracts/Cards'
import CardStep from '@/components/Cards/CardStep.vue'
import { ButtonAction } from '@/contracts/ButtonHeroAction'
import BlockButtons from '@/components/BlockButtons.vue'
import BlockBreadCrumbs from '@/components/BlockBreadCrumbs.vue'

export default defineComponent({
  name: 'SectionSteps',
  components: { BlockBreadCrumbs, BlockButtons, CardStep, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    cols: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    cards: {
      type: Array as PropType<ICardStep[]>,
      default: () => [],
    },
    actionButtons: {
      type: Array as PropType<ButtonAction[]>,
      default: () => [],
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    arrows: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const stepsStyles = computed(() => ({
      '--section-steps-background': props.backgroundColor,
    }))
    return {
      stepsStyles,
    }
  },
})
