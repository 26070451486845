


































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import BlockButtons from '../BlockButtons.vue'
import { CardStep as ICardStep } from '@/contracts/Cards'

export default defineComponent({
  name: 'CardStep',
  components: { BlockButtons },
  props: {
    card: {
      type: Object as PropType<ICardStep>,
      required: true,
    },
  },
  setup(props) {
    const cardStyles = computed(() => ({
      '--card-background-color': props.card.backgroundColor,
      '--card-title-color': props.card.titleColor,
      '--card-description-color': props.card.descriptionColor,
      '--card-content-alignment': props.card.contentAlignment,
    }))
    return {
      cardStyles,
    }
  },
})
