var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section-basic',{attrs:{"section-data":_vm.sectionData},scopedSlots:_vm._u([{key:"breadCrumbs",fn:function(){return [(_vm.needBreadCrumbs)?_c('block-bread-crumbs'):_vm._e()]},proxy:true},{key:"sectionContent",fn:function(){return [_c('f-row',{staticClass:"wrapper",style:(_vm.stepsStyles)},[_vm._l((_vm.cards),function(card,index){return _c('f-col',{key:index,staticClass:"card-col",attrs:{"col":_vm.cols[index] ||
          12 /
            (_vm.actionButtons && _vm.actionButtons.length
              ? _vm.cards.length + 1
              : _vm.cards.length),"md":4,"sm":2}},[_c('card-step',{attrs:{"card":card}}),_vm._v(" "),(_vm.arrows && index !== _vm.cards.length - 1)?_c('span',{staticClass:"step-arrow"}):_vm._e()],1)}),_vm._v(" "),(_vm.actionButtons && _vm.actionButtons.length)?_c('f-col',{staticClass:"card-col",attrs:{"col":_vm.cols[_vm.cards.length] ||
          12 /
            (_vm.actionButtons && _vm.actionButtons.length
              ? _vm.cards.length + 1
              : _vm.cards.length),"md":4,"sm":2,"justify":"center"}},[_c('block-buttons',{staticClass:"steps-buttons",attrs:{"button-actions":_vm.actionButtons,"alignment":"center"}})],1):_vm._e()],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }